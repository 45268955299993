<template>
  <v-container
    fluid
    style="display:flex; flex-direction: column; height: 100%;padding: 0; margin: 0; background: "
  >
    <Menu
      style="position:fixed; height: 50px; z-index: 1;background: #DBC7FF"
      :title="'Back'"
    />
    <div
      class="mb-3 d-flex flex-row"
      style="margin-top: 50px; height: 100%;overflow-x: hidden; overflow-y:auto; backgroud: #F4F9FF"
    >
      <v-layout style="display: flex; flex-direction: column;">
        <label class="item-title">New Referral</label>
        <label class="email-title ml-10 mb-5"
          >Add and verify the referral information</label
        >
        <v-row class="px-6">
          <v-col class="px-3" md="6" cols="12">
            <v-autocomplete
              :disabled="loading"
              :items="clients"
              dense
              outlined
              background-color="white"
              label="Select client"
              :filter="clientFilter"
              @change="onSelectedClient"
              :error-messages="clientError"
              v-model="client"
            >
              <template v-slot:selection="data">
                {{
                  data.item.first_name +
                    " " +
                    (data.item.last_name ? data.item.last_name : "")
                }}
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <editable-avatar
                    :image="data.item.image"
                    :size="40"
                    :editable="false"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.client_id" />
                  <v-list-item-title
                    v-html="
                      data.item.first_name +
                        ' ' +
                        (data.item.last_name ? data.item.last_name : '')
                    "
                  />
                  <v-list-item-subtitle v-html="data.item.phone" />
                  <v-list-item-subtitle v-html="data.item.email" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <div
              class="rounded-lg d-flex px-3 mb-5"
              style="background-color:#fff8; width: 100%; align-items: center;"
            >
              <editable-avatar
                :image="client ? client.image : null"
                :editable="false"
                :size="70"
                class="my-3"
              />
              <div
                v-if="client"
                class="px-4"
                style="font-family: 'Poppins-SemiBold';"
              >
                <span style="color: #feb934">Referred Client</span>
                <div>
                  <span style="color: #6E3DC6">Id: </span>
                  <span style="color: #472583">
                    {{ client.client_id }}
                  </span>
                </div>
                <div>
                  <span style="color: #6E3DC6">Name: </span>
                  <span style="color: #472583">
                    {{ client.first_name }} {{ client.last_name }}
                  </span>
                </div>
                <div>
                  <span style="color: #6E3DC6">Phone: </span>
                  <span style="color: #472583">
                    {{ client.phone }}
                  </span>
                </div>
              </div>
            </div>
            <v-autocomplete
              :disabled="loading"
              :items="organizations"
              dense
              outlined
              background-color="white"
              label="Select organization"
              :filter="organizationFilter"
              @change="onSelectedOrganization"
              :error-messages="organizationError"
            >
              <template v-slot:selection="data">
                {{ data.item.name }}
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <editable-avatar
                    :image="data.item.image"
                    :size="40"
                    :editable="false"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name" />
                  <v-list-item-subtitle v-html="data.item.phone" />
                  <v-list-item-subtitle v-html="data.item.email" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <div
              class="rounded-lg d-flex px-3 mb-5"
              style="background-color:#fff8; width: 100%; align-items: center;"
            >
              <editable-avatar
                :image="organization ? organization.image : null"
                :editable="false"
                :size="70"
                class="my-3"
              />
              <div
                v-if="organization"
                class="px-4"
                style="font-family: 'Poppins-SemiBold';"
              >
                <span style="color: #feb934">Referred Organization</span>
                <div>
                  <span style="color: #6E3DC6">Name: </span>
                  <span style="color: #472583">
                    {{ organization.name }}
                  </span>
                </div>
                <div>
                  <span style="color: #6E3DC6">Phone: </span>
                  <span style="color: #472583">
                    {{ organization.phone }}
                  </span>
                </div>
              </div>
              <div
                style="display: flex; flex-direction: row; align-items: flex-end; margin-bottom: 30px;"
              >
                <label
                  style="color: #6e3dc6; font-family: 'Poppins-Bold'; font-size: 14px;"
                >
                  Type:
                </label>
                <label
                  style="color: #472583; font-family: 'Poppins-SemiBold'; font-size: 18px; margin-left: 30px;"
                  v-text="referral.type"
                />
              </div>
            </div>
          </v-col>
          <v-col class="px-3" md="6" cols="12">
            <v-text-field
              v-model="referral.amount"
              label="Quantity"
              dense
              rounded
              outlined
              persistent-hint
              background-color="white"
              @change="onChange"
              class="mt-3"
              :error-messages="amountError"
              type="number"
              style="display: none"
            />
            <v-data-table
              :headers="headers"
              :items="employees"
              hide-default-footer
              :loading="loadingEmployees"
              class="px-3"
              show-select
              item-key="_id"
              v-model="selectedEmployees"
            >
              <template v-slot:[`item.image`]="{ item }">
                <v-avatar class="elevation-6 my-3 ml-5">
                  <v-img :src="item.image ? item.image : placeholderImage" />
                </v-avatar>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div v-html="item.first_name + ' ' + item.last_name" />
              </template>
              <template v-slot:[`item.phone`]="{ item }">
                <div v-html="formatPhoneNumber(item.phone)" />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onEditEmployee(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    Edit
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onDeleteEmployee(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </template>
                    Delete
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onViewHistory(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon
                          >mdi-clipboard-text-clock-outline</v-icon
                        ></v-btn
                      >
                    </template>
                    Intake Access History
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="goToPartnerChat(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-message-outline</v-icon></v-btn
                      >
                    </template>
                    Chat
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
            <v-textarea
              v-model="referral.description"
              label="Description"
              dense
              outlined
              background-color="white"
              @change="onChange"
              class="mt-3"
              rows="3"
              auto-grow
            />
          </v-col>
        </v-row>
        <div
          style="display:flex; justify-content:flex-end; flex-direction: row;"
        >
          <v-btn
            rounded
            width="200"
            color="primary"
            class="mb-3 mr-3"
            @click="onCancal"
            v-text="'Cancel'"
            text
          />
          <v-btn
            rounded
            width="200"
            color="primary"
            class="mb-3 mr-3"
            @click="onCreateReferral"
            :loading="loading"
          >
            Create
          </v-btn>
        </div>
      </v-layout>
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../components/core/EditableAvatar.vue";
export default {
  components: { Menu, EditableAvatar },
  data() {
    return {
      errorMessage: null,
      snackbar: false,
      loading: false,
      showPassword: false,
      serviceTypes: [],
      statusItems: ["Available", "Pending", "Disabled"],
      selectedImage: null,
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      client: {},
      organization: {},
      referral: {},
      clientError: null,
      organizationError: null,
      amountError: null,
      employees: [],
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "role" },
      ],
      loadingEmployees: false,
      placeholderImage: require("@/assets/default.jpg"),
      selectedEmployees: [],
    };
  },
  methods: {
    ...mapActions({
      addReferral: "referral/addReferral",
      fetchAllClients: "client/fetchAllClients",
      fetchAllOrganizations: "partner/fetchAllOrganizations",
      getValues: "variable/getValues",
      fetchEmployees: "partner/fetchEmployees",
    }),
    onCreateReferral() {
      if (!this.referral.client_id) {
        this.clientError = "Please select a client";
        return;
      } else {
        this.clientError = null;
      }
      if (!this.referral.organization_id) {
        this.organizationError = "Please select an organization";
        return;
      } else {
        this.organizationError = null;
      }
      if (this.selectedEmployees.length == 0) {
        this.errorMessage = "Please select at least one employee";
        this.snackbar = true;
        return;
      }
      this.referral.employees = this.selectedEmployees.map((e) => e._id);

      // if (!this.referral.amount) {
      //   this.amountError = "Please fill the amount";
      //   return;
      // } else {
      //   this.amountError = null;
      // }

      this.loading = true;
      this.addReferral(this.referral)
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            this.snackbar = true;
            this.errorMessage = error;
          }
        });
    },
    onChange() {
      this.errorMessage = null;
    },
    clientFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      return Object.keys(item).some(
        (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
      );
    },
    organizationFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      if (
        Object.keys(item).some(
          (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
        )
      ) {
        return true;
      }
      if (`${item.name}`.includes(searchText)) {
        return true;
      }
      return false;
    },
    onCancal() {
      this.$router.back();
    },
    onSelectedClient(item) {
      this.client = item;
      this.referral.client_id = item ? item.client_id : "";
      // this.$route.query = { ...this.$route.query, to: item.client_id };
      // console.log(this.$route.query);
    },
    onSelectedOrganization(item) {
      this.organization = item;
      this.referral.organization_id = item._id;
      this.referral.type = item.service;
      // this.$route.query.from = item._id;
    },
    checkQueryParams() {
      if (this.clients && this.clients.length && this.$route.query.to) {
        console.log(this.$route.query.to);
        this.clients.map((c) => {
          if (c.client_id == this.$route.query.to) {
            this.client = c;
            this.referral.client_id = c.client_id;
          }
        });
      }
      if (
        this.organizations &&
        this.organizations.length &&
        this.$route.query.from
      ) {
        this.organizations.map((p) => {
          if (p._id == this.$route.query.from) {
            this.organization = p;
            this.referral.organization_id = p._id;
            this.referral.type = p.service;
          }
        });
      }
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
  },
  computed: {
    ...mapGetters({
      clients: "client/getAllClients",
      organizations: "partner/getAllOrganizations",
      profile: "auth/getProfile",
    }),
  },
  watch: {
    "$route.query": function(value) {
      if (this.clients && this.clients.length && value.to) {
        this.clients.map((c) => {
          if (c._id == value.to) {
            this.client = c;
            this.referral.client_id = c.client_id;
          }
        });
      }
      if (this.organizations && this.organizations.length && value.from) {
        this.organizations.map((p) => {
          if (p._id == value.from) {
            this.organization = p;
            this.referral.organization_id = p._id;
            this.referral.type = p.service;
          }
        });
      }
    },
    organization(newValue) {
      if (newValue && newValue._id) {
        this.loadingEmployees = true;
        this.fetchEmployees({ _id: newValue._id })
          .then((res) => {
            this.loadingEmployees = false;
            this.employees = res;
          })
          .catch((error) => {
            this.loadingEmployees = false;
            console.log(error);
          });
      }
    },
  },
  created() {
    this.loading = true;
    this.fetchAllClients()
      .then(() => {
        this.fetchAllOrganizations()
          .then(() => {
            this.loading = false;
            this.checkQueryParams();
          })
          .catch((error) => {
            this.loading = false;
            if (error.response != undefined && error.response.status === 401) {
              this.tokenDialog = true;
            } else {
              console.log(error);
            }
          });
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });

    this.checkQueryParams();

    this.getValues({ title: "Partner Types" })
      .then((res) => {
        this.serviceTypes = res.map((v) => v.text);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
